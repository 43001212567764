import React, { useEffect, useState, useRef } from 'react';
//import algoliasearch from 'algoliasearch/lite';
import ReactDOM from "react-dom";
import { Routes, Route, Link, useLocation, useParams, Outlet } from "react-router-dom";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight,
  Configure,
  DynamicWidgets,
  RefinementList,
  Pagination,
  SortBy,
  CurrentRefinements,
  ClearRefinements,
  Panel,
  VoiceSearch,
} from 'react-instantsearch-dom';
import InfiniteHits from './components/InfiniteHits';
import Nav from './components/CentreNav';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import PropTypes from 'prop-types';
import './App.css';
import CustomRangeSlider from './components/PriceSlider';
import qs from 'qs';
import { createBrowserHistory } from 'history';
import Dropdown from 'react-dropdown-modal';
import 'react-dropdown-modal/lib/dropdown.css'; // CSS for the component
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ImageWithZoom, DotGroup, } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
// import Gallery from "react-photo-gallery";
import { SearchClient as TypesenseSearchClient } from "typesense";

let client = new TypesenseSearchClient({
  'nodes': [{
    'host': 'searchapi.wardrobeus.com', // For Typesense Cloud use xxx.a1.typesense.net
    'port': '443',      // For Typesense Cloud use 443
    'protocol': 'https'   // For Typesense Cloud use https
  }],
  'apiKey': '24969fd5534902dc04ec26924190c38c',
})

const DEBOUNCE_TIME = 400;
// const searchClient = algoliasearch(
//   'overallweb',
//   '6be0576ff61c053d5f9a3225e2a90f76'
// );

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "24969fd5534902dc04ec26924190c38c", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "searchapi.wardrobeus.com",
        port: "443",
        protocol: "https",
      },
    ],
  },
  cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    queryBy: "product_name,product_brand,product_brand_cleaned,product_category,product_type,product_tags,product_material,merchant,product_desc",
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;
// Step 1. Add the dictionaries to convert the names and the slugs
const encodedCategories = {
  Mens: 'Men',
  Womens: 'Women',
};

const decodedCategories = Object.keys(encodedCategories).reduce((acc, key) => {
  const newKey = encodedCategories[key];
  const newValue = key;

  return {
    ...acc,
    [newKey]: newValue
  };
}, {});

// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
// function getCategorySlug(name) {
//   return name
//     .split(' ')
//     .map(encodeURIComponent)
//     .join('+');
// }

// // Returns a name from the category slug.
// // The "+" are replaced by spaces and other
// // characters are decoded.
// function getCategoryName(slug) {
//   return slug
//     .split('+')
//     .map(decodeURIComponent)
//     .join(' ');
// }

  // Step 2. Update the getters to use the encoded/decoded values
  function getCategorySlug(name) {
    const encodedName = decodedCategories[name] || name;
  
    return encodedName
      .split(' ')
      .map(encodeURIComponent)
      .join('+');
  }
  
  function getCategoryName(slug) {
    const decodedSlug = encodedCategories[slug] || slug;
  
    return decodedSlug
      .split('+')
      .map(decodeURIComponent)
      .join(' ');
  }
  

// const createURL = state => `?${qs.stringify(state)}`;
const createURL = state => {
  const isDefaultRoute =
    !state.query &&
    state.page === 1 &&
    (state.refinementList 
      // && state.refinementList.product_brand.length 
      // && state.refinementList.product_gender.length 
      // && state.refinementList.product_category.length 
      // && state.refinementList.product_color.length 
      // && state.refinementList.product_type.length 
      // && state.refinementList.merchant.length 
      === 0) 
      // && (state.menu && !state.menu.categories)
      ;

  if (isDefaultRoute) {
    return '';
  }

  // const categoryPath = state.refinementList.product_gender
  //   ? `${getCategorySlug(state.refinementList.product_gender)}/`
  //   : '';

    const categoryPath = state.menu.category
    ? `${getCategorySlug(state.menu.category)}/`
    : '';

  const queryParameters = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }
  if (state.refinementList.product_brand) {
    queryParameters.brands = state.refinementList.product_brand.map(encodeURIComponent);
  }
  if (state.refinementList.product_gender) {
    queryParameters.gender = state.refinementList.product_gender.map(encodeURIComponent);
  }
  if (state.refinementList.product_category) {
    queryParameters.cat = state.refinementList.product_category.map(encodeURIComponent);
  }
  if (state.refinementList.product_color) {
    queryParameters.color = state.refinementList.product_color.map(encodeURIComponent);
  }
  if (state.refinementList.product_type) {
    queryParameters.type = state.refinementList.product_type.map(encodeURIComponent);
  }
  if (state.refinementList.merchant) {
    queryParameters.merchant = state.refinementList.merchant.map(encodeURIComponent);
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `/search/${categoryPath}${queryString}`;
};

const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';

// const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
const urlToSearchState = location => {
  const pathnameMatches = location.pathname.match(/search\/(.*?)\/?$/);
  const category = getCategoryName(
    (pathnameMatches && pathnameMatches[1]) || ''
  );
  const { query = '', page = 1, brands = [], gender = [], color = [], cat = [], type = [], merchant = [] } = qs.parse(
    location.search.slice(1)
  );
  // `qs` does not return an array when there's a single value.
  const allBrands = Array.isArray(brands) ? brands : [brands].filter(Boolean);
  const allGenders = Array.isArray(gender) ? gender : [gender].filter(Boolean);
  const allCategory = Array.isArray(cat) ? cat : [cat].filter(Boolean);
  const allColor = Array.isArray(color) ? color : [color].filter(Boolean);
  const allType = Array.isArray(type) ? type : [type].filter(Boolean);
  const allMerchant = Array.isArray(merchant) ? merchant : [merchant].filter(Boolean);

  return {
    query: decodeURIComponent(query),
    page,
    menu: {
      categories: decodeURIComponent(category),
    },
    refinementList: {
      product_brand: allBrands.map(decodeURIComponent),
      product_gender: allGenders.map(decodeURIComponent),
      product_category: allCategory.map(decodeURIComponent),
      product_color: allColor.map(decodeURIComponent),
      product_type: allType.map(decodeURIComponent),
      merchant: allMerchant.map(decodeURIComponent),
    },
  };
};


export function App() {
// const App = () => (
  const hist = createBrowserHistory(); 
  const loca = useLocation();
  return (
    <div>
      <Nav />
      <div class="b">
      <Routes>
        <Route path="/" element={<Home location={loca} history={hist}/>} />
        <Route path="search" element={<Home location={loca} history={hist}/>} />
        <Route path="product" element={<Products />} >
        <Route path=":productId" element={<Product />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="*" element={<Four />} />
      </Routes>
    </div>
    </div>
);
}

// App.js
function Home({ location, history }) {
  const [visible, setVisibile] = useState(false)
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      history.push(searchStateToUrl(updatedSearchState)); 
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  
 
  return (
    <InstantSearch indexName="clothes" 
    searchClient={searchClient}
    indexName="instant_search"
    searchState={searchState}
    onSearchStateChange={onSearchStateChange}
    createURL={createURL}
    >
    <Configure hitsPerPage={32} />
    <VoiceSearch />
    <SearchBox className="my-2 sticky top-1" />
    
    
    <div className='flex flex-wrap sticky top-14'>
    <div className="w-11/12 p-1">{/*<CurrentRefinements clearsQuery /> */}</div>  
    <div className="w-1/12 p-1"><ClearRefinements /></div>
    </div>
    
    <SortBy className="sticky top-24"
      defaultRefinement='clothes'
      items={[
        {label: 'Sort By: Default', value: 'clothes'},
        {label: 'Price Lowest First', value: 'clothes/sort/product_configs_price:asc'},
        {label: 'Price Highest First', value: 'clothes/sort/product_configs_price:desc'},
        {label: 'Newest', value: 'clothes/sort/added_ts:desc'},
        {label: 'Oldest', value: 'clothes/sort/added_ts:asc'},
      ]}
    />
    
    <div className='flex flex-wrap'>
      <aside className="w-1/3 p-5 scroll-auto hover:scroll-smooth">
      <Panel header="Filters" className='bg-slate-50 p-4 rounded-md sticky top-36 scroll-auto hover:scroll-smooth'>
      <p className='text-stone-600'>Gender</p>
      <RefinementList
          attribute="product_gender"
      />
      {/* <CustomRangeSlider min={0.0} max={999999.0} attribute="product_configs_price" /> */}

      <p class="mt-8 text-stone-600">Brand</p>
      <RefinementList
          attribute="product_brand"
          searchable
          limit={5}
          translations={{placeholder:'Search brands...'}}
      />
     <p class="mt-8 text-stone-600">Category</p>
      <RefinementList
          attribute="product_category"
          searchable
          limit={5}
          translations={{placeholder:'Search categories...'}}
      />
      <p class="mt-8 text-stone-600">Colour</p>
      <RefinementList
          attribute="product_color"
          limit={5}
          searchable
          translations={{placeholder:'Search colours...'}}
      />
      {/* <p class="mt-8">Type</p>
      <RefinementList
          attribute="product_type"
          searchable
          limit={5}
          translations={{placeholder:'Search product types...'}}
      /> */}
      <p class="mt-8 text-stone-600">Merchant</p>
      <RefinementList
          attribute="merchant"
          searchable
          limit={5}
          translations={{placeholder:'Search merchants...'}}
      />
      </Panel>
      </aside>
      <main className="w-2/3 flex flex-wrap">
        
        <InfiniteHits minHitsPerPage={16} />
      </main>
    </div>


    
  </InstantSearch>
  );
}

function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
    </>
  );
}


function Four() {
  return (
    <>
      <main>
 
        <p>
          There's nothing here!
        </p>
      </main>
    </>
  );
}


function Products() {
  return (
    <>
      <main>
 
      <Outlet /> 
      </main>
    </>
  );
}
const IMAGES =
[{
        src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
 
},
{
        src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
  
},

{
        src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
 
}]
function Product() {
  
  const [displayproduct, setDisplayproduct] = useState();
  const [noproduct, setNoproduct] = useState('false');
  const [nofetch, setFetchError] = useState('false');
  let params = useParams();
  const location = useLocation()
  const [hasLocation, sethasLocation] = useState(location)
  useEffect( () => {
    // Anything in here is fired on component mount.
    fetch(`http://localhost:8108/collections/clothes/documents/${params.productId}`, {
      headers: {
        "X-Typesense-Api-Key": "localtypesenseapikey"
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if('message' in data) {setNoproduct('true')}
        else {setDisplayproduct(data);
          setNoproduct('false')}
        
      })
      .catch(error => {
        setFetchError('true')
      });
 }, []);

  const { product_name } = location.state || 'Unknown Product'
  const { product_id } = location.state || 'Unknown Product'
  const { product_gender } = location.state || 'Unknown Product'
  const { product_color } = location.state || 'Unknown Product'
  const { product_brand } = location.state || 'Unknown Product'
  const { product_brand_cleaned } = location.state || 'Unknown Product'
  const { product_category } = location.state || 'Unknown Product'
  const { product_type } = location.state || 'Unknown Product'
  const { product_tags } = location.state || 'Unknown Product'
  const { product_desc } = location.state || 'Unknown Product'
  const { product_material } = location.state || 'Unknown Product'
  const { product_images } = location.state || ['https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg']//'Unknown Product'
  const { product_configs } = location.state || 'Unknown Product'
  const { product_configs_size } = location.state || 'Unknown Product'
  const { product_configs_price } = location.state || 'Unknown Product'
  const { product_url } = location.state || 'Unknown Product'
  const { external_id } = location.state || 'Unknown Product'
  const { merchant } = location.state || 'Unknown Product'
  const gallery_keys = ["src","thumbnail"]

  if (noproduct === 'true') {
    return <>Could not find that product.</>;
  }

  if (product_name === undefined && displayproduct === undefined && setFetchError==='false') {
    return <>Still loading...</>
  }

  if (product_name === undefined) {
    return (

      <>
      <main>
        {/* <h2>{product_name}</h2>
        <p>
          This is a product page for product: {params.productId}
          This is a product page for product: {product_id}
        </p> */}


<section>
  <div class="relative max-w-screen-xl px-4 py-8 mx-auto">
    <div class="grid items-start grid-cols-1 gap-8 md:grid-cols-2">
      
      
      <div style={{alignContent:'center', alignItems: 'center', width:'100%'}}>
      <Gallery style={{margin: '0 auto'}} images={displayproduct.product_images.map(function (row) { return gallery_keys.reduce(function (obj, key, i) { obj[key] = row; return obj;}, {});})} 
      rowHeight={500}/>
      
      </div>
      

      <div class="sticky top-0">
        {/* <strong class="border border-blue-600 rounded-full tracking-wide px-3 font-medium py-0.5 text-xs bg-gray-100 text-blue-600"> Pre Order </strong> */}

        <div class="flex justify-between mt-8">
          <div class="max-w-[35ch]">
            <h1 class="text-2xl font-bold">
              {displayproduct.product_name}
            </h1>

            <a
              href={displayproduct.product_url}
              target="_blank"
              //class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
             <p class="underline">
              {displayproduct.product_url}
              
            </p>
            </a>

            
          </div>

          <p class="text-lg font-bold">
            ${displayproduct.product_configs_price}
          </p>
        </div>

       
      </div>
    </div>
  </div>
</section>
      </main>
    </>
    )
  }
  return (
    <>
      <main>
        {/* <h2>{product_name}</h2>
        <p>
          This is a product page for product: {params.productId}
          This is a product page for product: {product_id}
        </p> */}


<section>
  <div class="relative max-w-screen-xl px-4 py-8 mx-auto">
    <div class="grid items-start grid-cols-1 gap-8 md:grid-cols-2">
      
      {/* <div class="grid grid-cols-2 gap-4 md:grid-cols-1">
        <div class="aspect-w-1 aspect-h-1">
          <img
            alt="Mobile Phone Stand"
            class="object-cover rounded-xl"
            src="https://images.unsplash.com/photo-1627844541143-a561a1a9b72a"
          />
        </div>

        <div class="grid grid-cols-2 gap-4 lg:mt-4">
          <div class="aspect-w-1 aspect-h-1">
            <img
              alt="Mobile Phone Stand"
              class="object-cover rounded-xl"
              src="https://images.unsplash.com/photo-1627844541143-a561a1a9b72a"
            />
          </div>

          <div class="aspect-w-1 aspect-h-1">
            <img
              alt="Mobile Phone Stand"
              class="object-cover rounded-xl"
              src="https://images.unsplash.com/photo-1627844541143-a561a1a9b72a"
            />
          </div>

          <div class="aspect-w-1 aspect-h-1">
            <img
              alt="Mobile Phone Stand"
              class="object-cover rounded-xl"
              src="https://images.unsplash.com/photo-1627844541143-a561a1a9b72a"
            />
          </div>

          <div class="aspect-w-1 aspect-h-1">
            <img
              alt="Mobile Phone Stand"
              class="object-cover rounded-xl"
              src="https://images.unsplash.com/photo-1627844541143-a561a1a9b72a"
            />
          </div>
        </div>
      </div> */}
      {/* <Gallery images={IMAGES} rowHeight={300}/> */}
      <div style={{alignContent:'center', alignItems: 'center', width:'100%'}}>

      {/* {hasLocation.state} */}
      <Gallery style={{margin: '0 auto'}} images={product_images.map(function (row) { return gallery_keys.reduce(function (obj, key, i) { obj[key] = row; return obj;}, {});})} 
      rowHeight={500}/>
      
      </div>
      {/* <Gallery direction={"row"} photos={product_images.map(function (row) { return gallery_keys.reduce(function (obj, key, i) { obj[key] = row; return obj;}, {});})} /> */}

{/*       

      <CarouselProvider
      // visibleSlides={2}
        naturalSlideWidth={100}
        naturalSlideHeight={80}
        totalSlides={3}
        infinite
        
      >
        <Slider
        >
          <Slide index={0}>I am the first Slide. <ImageWithZoom src={product_images[0]} /></Slide>
          <Slide index={1}>I am the second Slide.<ImageWithZoom src={product_images[1]} /></Slide>
          <Slide index={2}>I am the third Slide.<ImageWithZoom src={product_images[2]} /></Slide>
        
        </Slider>
        <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext>
        <DotGroup dotNumbers/>

      </CarouselProvider> */}

      <div class="sticky top-0">
        {/* <strong class="border border-blue-600 rounded-full tracking-wide px-3 font-medium py-0.5 text-xs bg-gray-100 text-blue-600"> Pre Order </strong> */}

        <div class="flex justify-between mt-8">
          <div class="max-w-[35ch]">
            <h1 class="text-2xl font-bold">
              {product_name}
            </h1>

            <a
              href={product_url}
              target="_blank"
              //class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
             <p class="underline">
              {product_url}
              
            </p>
            </a>

            {/* <div class="flex mt-2 -ml-0.5">
              <svg
                class="w-5 h-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>

              <svg
                class="w-5 h-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>

              <svg
                class="w-5 h-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>

              <svg
                class="w-5 h-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>

              <svg
                class="w-5 h-5 text-gray-200"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </div> */}
          </div>

          <p class="text-lg font-bold">
            ${product_configs_price}
          </p>
        </div>

        {/* <details class="relative mt-4 group"> */}
          {/* <summary class="block">
            <div>
              <div class="prose max-w-none group-open:hidden">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa veniam dicta beatae eos ex error culpa delectus rem tenetur, architecto quam nesciunt, dolor veritatis nisi minus inventore, rerum at recusandae?
                </p>
              </div>

              <span class="mt-4 text-sm font-medium underline cursor-pointer group-open:absolute group-open:bottom-0 group-open:left-0 group-open:mt-0">
                Read More
              </span>
            </div>
          </summary> */}

          {/* <div class="pb-6 prose max-w-none">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa veniam dicta beatae eos ex error culpa delectus rem tenetur, architecto quam nesciunt, dolor veritatis nisi minus inventore, rerum at recusandae?
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat nam sapiente nobis ea veritatis error consequatur nisi exercitationem iure laudantium culpa, animi temporibus non! Maxime et quisquam amet. A, deserunt!
            </p>
          </div> */}
        {/* </details> */}

        {/* <form class="mt-8">
          <fieldset>
            <legend class="mb-1 text-sm font-medium">Color</legend>

            <div class="flow-root">
              <div class="flex flex-wrap -m-0.5">
                <label for="color_tt" class="cursor-pointer p-0.5">
                  <input type="radio" name="color" id="color_tt" class="sr-only peer" />

                  <span class="inline-block px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    Texas Tea
                  </span>
                </label>

                <label for="color_fr" class="cursor-pointer p-0.5">
                  <input type="radio" name="color" id="color_fr" class="sr-only peer" />

                  <span class="inline-block px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    Fiesta Red
                  </span>
                </label>

                <label for="color_cb" class="cursor-pointer p-0.5">
                  <input type="radio" name="color" id="color_cb" class="sr-only peer" />

                  <span class="inline-block px-3 py-1 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    Cobalt Blue
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset class="mt-4">
            <legend class="mb-1 text-sm font-medium">Size</legend>

            <div class="flow-root">
              <div class="flex flex-wrap -m-0.5">
                <label for="size_xs" class="cursor-pointer p-0.5">
                  <input type="radio" name="size" id="size_xs" class="sr-only peer" />

                  <span class="inline-flex items-center justify-center w-8 h-8 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    XS
                  </span>
                </label>

                <label for="size_s" class="cursor-pointer p-0.5">
                  <input type="radio" name="size" id="size_s" class="sr-only peer" />

                  <span class="inline-flex items-center justify-center w-8 h-8 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    S
                  </span>
                </label>

                <label for="size_m" class="cursor-pointer p-0.5">
                  <input type="radio" name="size" id="size_m" class="sr-only peer" />

                  <span class="inline-flex items-center justify-center w-8 h-8 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    M
                  </span>
                </label>

                <label for="size_l" class="cursor-pointer p-0.5">
                  <input type="radio" name="size" id="size_l" class="sr-only peer" />

                  <span class="inline-flex items-center justify-center w-8 h-8 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    L
                  </span>
                </label>

                <label for="size_xl" class="cursor-pointer p-0.5">
                  <input type="radio" name="size" id="size_xl" class="sr-only peer" />

                  <span class="inline-flex items-center justify-center w-8 h-8 text-xs font-medium border rounded-full group peer-checked:bg-black peer-checked:text-white">
                    XL
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <div class="flex mt-8">
            <div>
              <label for="quantity" class="sr-only">Qty</label>

              <input
                type="number"
                id="quantity"
                min="1"
                value="1"
                class="w-12 py-3 text-xs text-center border-gray-200 rounded no-spinners"
              />
            </div>

            <button
              type="submit"
              class="block px-5 py-3 ml-3 text-xs font-medium text-white bg-green-600 rounded hover:bg-green-500"
            >
              Add to Cart
            </button>
          </div>
        </form> */}
      </div>
    </div>
  </div>
</section>
      </main>
    </>
  );
}

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object.isRequired,
};


export default App;
