import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import Hit from './Hit';
import { Routes, Route, Link, useLocation, useParams, Outlet } from "react-router-dom";

class InfiniteHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refineNext: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refineNext } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;

    return (
      <div className="ais-InfiniteHits flex flex-wrap">
        <ul className="ais-InfiniteHits-list flex">
          {hits.map(hit => (
            <li key={hit.id} className="ais-InfiniteHits-item w-6/12 sm:w-5/12 md:w-1/4 lg:w-1/5 xl:w-1/5">
              <Link to={"/product/" + hit.id} state={{product_name:hit.product_name
                                                    , product_id:hit.id
                                                    , product_gender:hit.product_gender
                                                    , product_gender: hit.product_gender
                                                    , product_color: hit.product_color
                                                    , product_brand: hit.product_brand
                                                    , product_brand_cleaned: hit.product_brand_cleaned
                                                    , product_category: hit.product_category
                                                    , product_type: hit.product_type
                                                    , product_tags: hit.product_tags
                                                    , product_desc: hit.product_desc
                                                    , product_material: hit.product_material
                                                    , product_images: hit.product_images
                                                    , product_configs: hit.product_configs
                                                    , product_configs_size: hit.product_configs_size
                                                    , product_configs_price: hit.product_configs_price
                                                    , product_url: hit.product_url
                                                    , external_id: hit.external_id
                                                    , merchant: hit.merchant
                                                    }} 
              ><Hit hit={hit} /></Link>
            </li>
          ))}
          <li
            className="ais-InfiniteHits-sentinel"
            ref={c => (this.sentinel = c)}
          />
        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
