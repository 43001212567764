import React from 'react';
import { Highlight } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import '../App.css';


const Hit = ({ hit }) => (
  <div className='resultbox'>
      <img src={hit.product_images[0]} align="center" alt={hit.product_name} aspect-auto/>
      <div align="center" className="hit-price">${hit.product_configs_price}</div>
      <div className="hidden hover:block">
        <div className="hit-price">${hit.product_configs_price}</div>
        <div className="hit-name truncate hover:visible">
          <Highlight attribute="product_name" hit={hit} />
        </div>
        


        <div className='rows'>
        <div className="hit-brand row truncate">{hit.product_brand}</div>
        <div className="hit-merchant row truncate">{hit.merchant}</div>
        </div>
      </div>
      
  </div>
);

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Hit;

